/**
 * Functionality relating to the User Options widget.
 */

import UserState from './user-state';

/**
 * Determine whether to show the sign in or sign out link.
 */
function updateAccountLinks() {
  const activeLink = UserState.signedIn()
    ? document.querySelector('.js-signout-link')
    : document.querySelector('.js-signin-link');

  if (activeLink) {
    activeLink.style.display = 'inline-block';
  }
}

const UserOptions = {
  updateAccountLinks,
};

export default UserOptions;
