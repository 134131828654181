/**
 * Created by steve.gibbings on 23/06/2016.
 */

import '@babel/polyfill';
import commonVM from 'js/view-model/common-vm';
import UserOptions from 'js/modules/user-options';

require('scss/common.scss');

commonVM.init();

document.addEventListener('DOMContentLoaded', () => {
  UserOptions.updateAccountLinks();
});
