import { adManager } from '@immediate_media/advertising';

const noop = () => {};

window.IM = window.IM || {};
window.IM.RT = window.IM.RT || {};

// Broadcast listing is frankly a mess and likely to be rewritten at some point.
// To avoid getting into that now just replicate the old ad manager interface.
window.IM.adManager = {
  loadAds: noop,
  addSlot: noop,
  ...adManager,
};
