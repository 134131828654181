
/**
 * Determine if the current user is signed in.
 * @return {bool}
 */
function signedIn() {
  return document.cookie.indexOf('AuthToken') !== -1;
}

const UserState = {
  signedIn,
};

export default UserState;
