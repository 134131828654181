/**
 * Entry point to initialise all common im-fabric javascript
 * <br/>
 * {@link https://github.immediate.co.uk/WCP/im-fabric/blob/develop/src/js/view-model/common-vm.js}
 *
 * @module view-model/common-vm
 * @author Steve Gibbings <steve.gibbings@immediate.co.uk>
 * @version 1.0
 */
// import Modernizr from 'modernizr';
// import loadCss from 'fg-loadcss';

import { waitForConsent } from '@immediate_media/consent-checker';

import pubSub from 'pubsub-js';
import permutiveActivator from 'js/modules/permutive-activator';
import iconHandler from 'js/modules/icon-handler';
import brightcoveGTM from 'js/modules/brightcove-gtm';
import navigationUI from 'js/components/navigation-ui';
import headerUI from 'js/components/header-ui';
import adBannerUI, { events as bannerUIEvents } from 'js/components/ad-banner-ui';
import socialShareUI from 'js/components/social-share-ui';
import betaBannerUI from 'js/components/beta-banner-ui';
import emailSignupUI from 'js/components/email-signup-ui';
import userControlsUI from 'js/components/user-controls-ui';
import carouselUI from 'js/components/carousel-ui';
import focusBorderSupress from 'js/components/focus-border-supress-ui.js';
import navigationSearchUI from 'js/components/navigation-search-ui';
import collapseUI from 'js/components/collapse-ui';
import passwordToggleUI from 'js/components/Password-Toggle-ui';
import defaultSettings from 'js/modules/default-settings';
import lazyLoadImages from 'js/components/lazy-load-images';
import memberStatusChange from 'js/modules/member-status-change';

// RT specific imports
// Sets a copy of the admanager on the window object for TV Listings
import '../modules/ad-manager-wrapper';

window.withConsent = window.withConsent || waitForConsent;

/* eslint-disable global-require */
window.React = require('react');
window.ReactDOM = require('react-dom');
/* eslint-enable */

export default (() => {
  const init = (options) => {
    const body = document.getElementsByTagName('body')[0];
    let pageCommonSettings;
    try {
      pageCommonSettings = JSON.parse(
        document.querySelector('body').dataset.pageCommonSettings,
      );
    } catch (error) {
      pageCommonSettings = {};
    }
    const settings = Object.assign(defaultSettings, pageCommonSettings, options);

    if ('querySelector' in document
      && 'localStorage' in window
      && 'addEventListener' in window) {
      // bootstrap the javascript application
      // TODO: Sort how we are going to load grunticon icons
      /*
       This code was included to async load fallbacks for icons when the data.svg.css
       was the default by being link'd in the header
       As the icon-search needed two colours for the normal and hover state I used
       the enhanceSVG:true option in grunticon to embed an SVG element and use
       css to colour it.
       */
      /* Modernizr.on('datauri', function(hasDataURI) {
       const isIE = document.documentMode || false;
       if (hasDataURI && isIE) {
       loadCss('../../css/icons.data.png.min.css');
       } else if (!hasDataURI){f
       loadCss('../../css/icons.fallback.min.css');
       }
       }); */
      permutiveActivator.init();
      iconHandler.init();
      brightcoveGTM.init();
      memberStatusChange.init();
      betaBannerUI.init();
      navigationUI.init();
      userControlsUI.init();
      carouselUI.init();
      navigationSearchUI.init();
      adBannerUI.init();

      if (!settings.disableHeaderUI) {
        headerUI.init();

        pubSub.subscribe(bannerUIEvents.HEADER_UI_BANNER_AD_HEIGHT_CHANGE,
          (eventName, { finalHeight }) => {
            adBannerUI.updateBannerAdHeight(finalHeight);
          });
      }
      socialShareUI.init();
      emailSignupUI.init();
      focusBorderSupress.init();
      collapseUI.init();
      passwordToggleUI.init();
      lazyLoadImages.init();

      // TODO refactor approach to VM's (multiple entry points)
      const isArticle = body.classList.contains('single');
      const isUserSettings = body.classList.contains('user-settings');
      const isAdditionalUserSettings = document.querySelector('.js-additional-user-settings');
      const isSearchUniversal = body.classList.contains('search-universal');
      const isCompetition = body.classList.contains('single-competition');
      const isVenue = body.classList.contains('single-venue');
      const isArchive = body.classList.contains('archive');
      const hasNewsletterSignUpWidget = document.querySelector('.widget-newsletter-sign-up');
      const hasAvatar = document.querySelector('#avatar-box');
      const hasRelatedContent = document.querySelector('.js-dynamic-related-content');

      let moduleToLoad;
      let moduleFabric = true;

      const moduleToLoadWidget = [
        {
          selector: hasNewsletterSignUpWidget,
          vm: 'newsletter-signup-widget-vm',
        },
        {
          selector: hasAvatar,
          vm: 'avatar-vm',
        },
        {
          selector: hasRelatedContent,
          vm: 'dynamic-related-vm',
        },
      ];

      if (isSearchUniversal) {
        moduleToLoad = 'search-universal-vm';
      } else if (isUserSettings) {
        moduleToLoad = 'user-settings-vm';
      } else if (isAdditionalUserSettings) {
        moduleToLoad = 'additional-user-settings-vm';
      } else if (isCompetition) {
        moduleToLoad = 'competition-vm';
      } else if (isVenue) {
        moduleToLoad = 'venue-vm';
      } else if (isArchive) {
        moduleToLoad = 'archive-vm';
      } else if (isArticle) {
        moduleToLoad = 'article-vm';
        moduleFabric = false;
      }

      moduleToLoadWidget.forEach((item) => {
        if (item.selector) {
          System.import(`../../../../../im-fabric/assets/src/js/view-model/${item.vm}`)
            .then((module) => {
              module.default.init();
            })
            .catch((error) => {
              throw (error);
            });
        }
      });

      if (moduleToLoad) {
        if (moduleFabric) {
          // Webpack appParent path won't work with System.import
          System.import(`../../../../../im-fabric/assets/src/js/view-model/${moduleToLoad}`)
            .then((module) => {
              module.default.init();
            })
            .catch((error) => {
              throw (error);
            });
        } else {
          System.import(`./${moduleToLoad}`)
            .then((module) => {
              module.default.init();
            })
            .catch((error) => {
              throw (error);
            });
        }
      }

      window.addEventListener('load', () => {
        // We may need to think about a catch to see if URL contains a gallery hook to execute
        // loading asap.
        const hasLightbox = body.classList.contains('js-has-gallery');
        if (hasLightbox) { // reverse - this is just for loading while we don't have class
          System.import('js/view-model/lightbox-vm')
            .then((module) => {
              module.default.init();
            })
            .catch((error) => {
              throw (error);
            });
        }
      });
    }
  };

  return {
    /**
     * Initialise the view model
     * @function
     * @param {Object} [options] - override defaults and any
     * data-page-common-settings from the body element.
     * <br/>
     * data-page-common-settings can be set by twig templates that
     * extend base.twig by using the Twig "set" command.
     * <br/>
     * <pre><code>
     * {% extends "base.twig" %}
     * {% set page_common_settings = "{'disableHeaderUI': true}" %}
     * </code></pre>
     * <br/>
     */
    init,
  };
})();
